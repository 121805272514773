@use 'sass:meta';
@use '../common/utility';
@use '../common/type-style' as *;
@use '../config/typography';

@mixin -type-style-utility($type-style) {
    @include type-style($type-style, $important: true);
}

@mixin -align-utility($align) {
    text-align: $align !important;
}

@mixin styles {
    @include utility.generate-all-modifiers(
        '.u-text',
        typography.$type-style-names,
        meta.get-mixin('-type-style-utility')
    );

    @include utility.generate-all-modifiers(
        '.u-text',
        (start, center, end),
        meta.get-mixin('-align-utility')
    );

    .u-nowrap {
        white-space: nowrap !important;
    }
}
