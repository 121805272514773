@use 'sass:meta';
@use '../config';
@use 'common/respond';
@use 'common/utility';

///
/// The gap utility classes for each step in the spacing scale.
/// These classes only control the --gap variable, not the gap property itself.
///
/// Usage:
///
/// <div class="l-stack u-gap--xs">
///    <div>First child</div>
///    <div>Second child</div>
/// </div>
///
/// Configuration:
///     $spacing-scale-positive-multipliers     The positive multipliers for the spacing scale.
///     $spacing-scale-negative-multipliers     The negative multipliers for the spacing scale.
///
/// Block:
///     .u-gap          The default gap size (the same as .u-gap--s).
///
/// Elements:
///     <none>
///
/// Modifiers:
///     .u-gap--{N}          N is the step in the spacing scale (e.g. xs, s, m, l, xl, 2xl etc.).
///     .u-gap--{N}@{BP}     Variation of the modifier at the breakpoint BP (e.g. xs, s, m etc.).
///

@mixin -gap-utility($size) {
    --gap: var(--space-#{$size}) !important;
}

@mixin styles {
    $-ns: u-gap;

    .#{$-ns} {
        --gap: var(--space-s);

        @include utility.generate-all-modifiers(
            '&',
            config.$space-scale,
            meta.get-mixin('-gap-utility')
        );
    }
}
