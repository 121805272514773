@use 'common/respond';

@mixin styles {
    $display-values: block, inline, flex, inline-flex, grid;

    @each $display in $display-values {
        .u-show#{if($display == block, '', '-' + $display)} {
            display: $display !important;
        }
    }

    @each $breakpoint in respond.breakpoint-names() {
        @each $display in $display-values {
            .u-show#{if($display == block, '', '-' + $display)} {
                &\@#{$breakpoint} {
                    display: none !important;
                }
            }
        }
    }

    @each $breakpoint in respond.breakpoint-names() {
        @each $display in $display-values {
            .u-show#{if($display == block, '', '-' + $display)} {
                @include respond.until($breakpoint) {
                    &\@#{$breakpoint} {
                        display: $display !important;
                    }
                }
            }
        }
    }
}
