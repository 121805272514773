@use 'sass:math';
@use '../config/metrics';
@use '../config/typography';
@use '../common/font-stack' as *;

@mixin styles {
    $-font-stack-body: font-stack(typography.$font-family-body);
    $-font-stack-title: font-stack(typography.$font-family-title);

    :where(:root) {
        // Prevents overflow of text outside of the containing element
        overflow-wrap: break-word;

        // Remove the tap highlight color in iOS
        -webkit-tap-highlight-color: transparent;

        // Turn-off subpixel antialiasing in macOS
        -webkit-font-smoothing: antialiased;

        // Prevents mobile browsers from increasing the default font-size when you switch from portrait to landscape:
        -webkit-text-size-adjust: none;
        text-size-adjust: none;
    }

    :where(body) {
        // The default fluid font size
        font-size: var(--step-0);
        line-height: var(--step-0-height);

        // The default font stack
        font-family: $-font-stack-body;
    }

    :where(h1, h2, h3, h4, h5, h6) {
        // Make headings bold and wrap text nicer
        font-weight: bold;
        text-wrap: balance;

        // Set the alternate heading font, if different
        @if $-font-stack-title != $-font-stack-body {
            font-family: $-font-stack-title;
        }
    }

    :where(p, h1, h2, h3, h4, h5, h6) {
        // Automatic hyphenation can improve text readability
        hyphens: typography.$hyphens;
    }

    :where(input, button, textarea, select, address) {
        // Make sure form elements are rendered consistently
        font: inherit;
    }

    // Generate a fluid type scale
    @for $h from 1 through 6 {
        :where(h#{$h}) {
            $step: math.max(0, metrics.$type-scale-total-positive-steps - $h + 1);

            font-size: var(--step-#{$step});
            line-height: var(--step-#{$step}-height);
        }
    }

    :where(small) {
        font-size: if(metrics.$type-scale-total-negative-steps > 0, var(--step--1), 80%);
    }

    :where(
            body,
            h1,
            h2,
            h3,
            h4,
            h5,
            h6,
            p,
            figure,
            pre,
            address,
            iframe,
            form,
            blockquote,
            table,
            dl,
            dd
        ) {
        // Remove default margins
        margin: 0;
    }

    :where(ul, ol) {
        padding: 0;
        margin: 0;

        // indent list items by default
        > li {
            margin-inline-start: 1.3em;
        }
    }

    :where(dl) {
        padding: 0;

        > dd {
            margin-inline-start: 1.3em;
            margin-block-end: 0.25em;
        }
    }

    :where(ul[role='list'], ol[role='list']) {
        // Remove list styles on ul, ol elements with a list role which signifies that it will be styles as a "ui list"
        list-style: none;

        > li {
            margin: 0;
        }
    }

    :where(dl[role='list']) > dd {
        margin: 0;
    }

    // Make "monospace elements" consistent across browsers
    :where(pre, code, kbd, samp) {
        // stylelint-disable-next-line font-family-no-duplicate-names
        font-family: monospace, monospace;
        font-size: 1em;
        overflow: auto;
    }

    :where(pre) {
        white-space: pre-wrap;
    }

    :where(b, strong, dt, th) {
        // Make "bold" elements consistent across browsers
        font-weight: bolder;
    }

    :where(abbr[title]) {
        // Fix text decoration of <abbr> in Safari
        text-decoration: underline;
        text-decoration: underline dotted;
    }

    :where(hr) {
        // Make <hr> consistent across browsers
        color: inherit;
        block-size: 0;
        inline-size: 100%;
        max-inline-size: none;
        border-color: currentColor;
        border-style: solid;
        border-width: 1px 0 0;
        overflow: visible;
    }

    // Prevent <sub> and <sup> elements from affecting the line height in all browsers
    :where(sub, sup) {
        font-size: 75%;
        line-height: 0;
        position: relative;
        vertical-align: baseline;
    }

    :where(sub) {
        inset-block-end: -0.25em;
    }

    :where(sup) {
        inset-block-start: -0.5em;
    }
}
