// Note: this module is not configurable through the library entry point.
// If you need to configure these values, do so before any other @use rules.

$rem: 16 !default; // The rem to pixel ratio (unitless).

@mixin styles {
    :root {
        --rem: #{$rem};
    }
}
