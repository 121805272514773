@use '../config';

///
/// The Cluster Layout primitive is used to group a set of elements that differ in length and are liable to wrap.
/// Buttons that appear together at the end of forms are ideal candidates, as well as lists of tags, keywords, or
/// other meta information.
///
///                           Cluster Layout
///   /-----------------------------------------------------------------\
///   | +---------+ # +-------+ # +-------------+ # +-----------------+ |
///   | |         | # |       | # |             | # |                 | |
///   | |         | # |       | # |             | # |                 | |
///   | |         | # |       | # |             | # |                 | |
///   | +---------+ # +-------+ # +-------------+ # +-----------------+ |
///   \-----------------------------------------------------------------/
///                 ↑                    ↑
///                Gap                Children
///
///                                 |
///                            [ wraps as ]
///                                 |
///                                 v
///
///                    /---------------------------\
///                    | +---------+ # +-------+   |
///                    | |         | # |       |   |
///                    | |         | # |       |   |
///                    | |         | # |       |   |
///                    | +---------+ # +-------+   |
///                    | ######################### |
///                    | +-------------+           |
///                    | |             |           |
///                    | |             |           |
///                    | |             |           |
///                    | +-------------+           |
///                    | ######################### |
///                    | +-----------------+       |
///                    | |                 |       |
///                    | |                 |       |
///                    | |                 |       |
///                    | +-----------------+       |
///                    \---------------------------/
///
///
///
///                           Cluster "Grid" Layout
///
///     /-----------------------------------------------------------------\
///     | +-----------------+ # +-----------------+ # +-----------------+ |
///     | |                 | # |                 | # |                 | |
///     | |                 | # |                 | # |                 | |
///     | |                 | # |                 | # |                 | |
///     | +-----------------+ # +-----------------+ # +-----------------+ |
///     | ############################################################### |
///     |             +-----------------+ # +-----------------+           |
///     |             |                 | # |                 |           |
///     |             |                 | # |                 |           |
///     |             |                 | # |                 |           |
///     |             +-----------------+ # +-----------------+           |
///     \-----------------------------------------------------------------/
///
///
/// The Cluster "Grid" Layout is a variation of the Cluster Layout that displays children
/// in a fixed number of columns.
/// The alignment of the elements in the last row is controlled by the --inline-align variable, which
/// in the .l-cluster--grid defaults to center.
///
/// The accompanying cluster-grid.max-columns() mixin can be used to set the --columns variable
/// based on the container inline-size and the minimum item size, helping to implement a responsive grid.
///
///
/// Configuration:
///     $cluster-split-point            The maximum number of split points in cluster layouts. Defaults to 6.
///     $cluster-grid-max-columns       The maximum number of columns in cluster--grid layouts. Defaults to $grid-columns.
///     $cluster-grid-default-columns   The default number of columns in cluster--grid layouts. Defaults to $grid-columns / 4.
///
/// Variables:
///     --gap                           The size of padding between elements. Defaults to var(--space-s).
///     --cluster-gap                   The cluster-specific gap that overrides the --gap. Unset by default.
///     --inline-align                  The alignment of the elements in the inline direction. Defaults to flex-start.
///     --block-align                   The alignment of the elements in the block direction. Defaults to center.
///     --columns                       The number of columns in the l-cluster--grid. Defaults to $cluster-grid-default-columns.
///
/// Usage:
///
///     <ul class="l-cluster u-gap--xs" role="list">
///         <li>Lorem</li>
///         <li>Ipsum</li>
///         <li>Dolor</li>
///         <li>Sit</li>
///         <li>Amet</li>
///     </ul>
///
/// Block:
///    .l-cluster
///
/// Elements:
///    .l-cluster__split            The first element in the "split group".
///    .l-cluster__split-after      The element after which the "split group" is created.
///
/// Modifiers:
///    .l-cluster--split-{N}        Split after {N}th child (where {N} goes from 1 to $cluster-split-point).
///    .l-cluster--split-last-{N}   Split before the last {N}th child (where {N} goes from 1 to $cluster-split-point).
///    .l-cluster--grid             The grid-like layout. Displays children in a fixed number of columns.
///    .l-cluster--grid-{N}         The grid-like layout with {N} columns (where {N} goes from 1 to $cluster-grid-max-columns).
///

@mixin styles {
    $-ns: l-cluster;

    .#{$-ns} {
        --gap: var(--space-s);

        display: flex;
        flex-wrap: wrap;
        gap: var(--cluster-gap, var(--gap));
        justify-content: var(--inline-align, flex-start);
        align-items: var(--block-align, center);

        & > * {
            margin-inline: 0;
        }

        &__split {
            margin-inline-start: auto;
        }

        &__split-after {
            margin-inline-end: auto;

            & + * {
                margin-inline-start: 0;
            }
        }

        @for $i from 1 through config.$cluster-split-points {
            &--split-#{$i} > :nth-child(#{$i}) {
                margin-inline-end: auto;
            }
            &--split-last-#{$i} > :nth-last-child(#{$i}) {
                margin-inline-start: auto;
            }
        }

        &--grid {
            --columns: #{config.$cluster-grid-default-columns};
            --item-size: var(
                --cluster-grid-item-size,
                calc(
                    (100% - var(--cluster-gap, var(--gap)) * (var(--columns) - 1)) / var(--columns)
                )
            );
            --inline-align: center;

            & > * {
                flex-grow: 1;
                flex-basis: var(--item-size);
                max-inline-size: var(--item-size);
            }

            @for $i from 1 through config.$cluster-grid-max-columns {
                &-#{$i} {
                    --columns: #{$i};
                }
            }
        }
    }
}
