@use 'sass:map';
@use '../common/util';
@use '../common/transition' as *;
@use '../common/type-style' as *;
@use '../config/modules/button' as config;
@use '../common/bem';

$-variants: config.$button-variants;
$-config: util.transpose(config.$button-config);

@mixin -colors($variant, $state) {
    --button-color: #{map.get($-config, $variant, 'colors', $state, 'text')};
    --button-background: #{map.get($-config, $variant, 'colors', $state, 'background')};
    --button-border-color: #{map.get($-config, $variant, 'colors', $state, 'border')};
    --button-icon-color: #{map.get($-config, $variant, 'colors', $state, 'icon')};
    --button-icon-background: #{map.get($-config, $variant, 'colors', $state, 'icon-background')};
    --button-icon-border-color: #{map.get($-config, $variant, 'colors', $state, 'icon-border')};
}

@function -param($variant, $param, $default: null, $inherits: null) {
    $value: map.get($-config, $variant, $param);
    @if $value == inherit and $inherits {
        @return -param($variant, $param: $inherits, $default: $default);
    }
    @return if($value, $value, $default);
}

@mixin styles {
    $-ns: 'm-button';

    .#{$-ns} {
        display: inline-flex;
        flex: 0 0 auto;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        user-select: none;
        white-space: nowrap;
        text-decoration: none;
        outline: none;
        border: var(
            --button-border,
            var(--button-border-width) var(--button-border-style, solid) var(--button-border-color)
        );
        padding: 0;
        inline-size: var(--button-inline-size);
        max-inline-size: var(--button-max-inline-size);
        min-inline-size: var(--button-min-inline-size);
        block-size: var(--button-size);
        border-radius: var(--button-radius);
        gap: var(--button-gap, 0.5em);
        background: var(--button-background);
        color: var(--button-color);

        &:any-link {
            color: var(--button-color);
        }

        &[disabled] {
            cursor: not-allowed;
        }

        &:not(:has(> *)),
        &__text {
            padding: var(--button-padding);
            text-align: var(--button-text-align, center);
        }

        &__text {
            flex-grow: 1;
        }

        &:has(> .m-button__icon:only-child) {
            min-inline-size: var(--button-icon-size);
        }

        @include bem.element('icon') {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            inline-size: var(--button-icon-size);
            block-size: var(--button-icon-size);
            background: var(--button-icon-background);
            color: var(--button-icon-color);
            flex: 0 0 auto;
            margin: var(--button-icon-margin, 0);
            border: var(
                --button-icon-border,
                var(--button-icon-border-width) var(--button-icon-border-style, solid)
                    var(--button-icon-border-color)
            );
            border-radius: var(--button-icon-radius);
            transform: rotate(var(--button-icon-angle, 0deg));

            &:only-child {
                background: transparent;
                color: var(--button-text-color);
            }
        }

        $-interaction-states: (
            'hover': ':hover',
            'focus': ':focus-visible',
            'active': ':active',
            'disabled': '[disabled]',
        );

        @each $variant in $-variants {
            @include bem.modifier($variant) {
                @include -colors($variant, 'default');

                --button-border-width: #{-param($variant, 'border-width', 0px)};
                --button-border-style: #{-param($variant, 'border-style', solid)};
                --button-padding: #{-param($variant, 'padding')};
                --button-inline-size: #{-param($variant, 'inline-size')};
                --button-max-inline-size: #{-param($variant, 'max-inline-size')};
                --button-min-inline-size: #{-param($variant, 'min-inline-size')};
                --button-size: #{-param($variant, 'size')};
                --button-radius: #{-param($variant, 'radius', 0px)};
                --button-gap: #{-param($variant, 'gap')};
                --button-text-align: #{-param($variant, 'text-align')};
                --button-icon-size: #{-param($variant, 'icon-size')};
                --button-icon-margin: #{-param($variant, 'icon-margin')};
                --button-icon-padding: #{-param($variant, 'icon-padding')};
                --button-icon-radius: #{-param($variant, 'icon-radius', 0px, $inherits: 'radius')};
                --button-icon-border-width: #{-param(
                        $variant,
                        'icon-border-width',
                        0px,
                        $inherits: 'border-width'
                    )};

                $-type-style: -param($variant, 'type-style');

                @if $variant == 'default' or $-type-style != -param('default', 'type-style') {
                    @include type-style($-type-style);
                }

                @include util.apply-styles(
                    map.get($-config, $variant, 'styles'),
                    'default',
                    map.get($-config, $variant)
                );

                @each $state, $selector in $-interaction-states {
                    &#{$selector} {
                        @include -colors($variant, $state);
                        @include util.apply-styles(
                            map.get($-config, $variant, 'styles'),
                            $state,
                            map.get($-config, $variant)
                        );
                    }
                }
            }
        }
    }
}
