@mixin styles {
    :where(table) {
        // Make table size consistent in all browsers
        inline-size: fit-content;

        // Collapse border spacing in all browsers
        border-collapse: collapse;

        // Correct table border color in Chrome, Edge, and Safari
        border-color: currentColor;

        // Remove text indentation from table contents in Chrome, Edge, and Safari
        text-indent: 0;
    }

    :where(td, th) {
        // Make table cells consistent in all browsers
        vertical-align: top;
        padding: 0.5rem;
        border: 0.5px solid currentColor;
    }

    :where(caption, th) {
        // Make the table caption consistent in all browsers
        text-align: start;
        font-weight: bold;
    }
}
