@use 'sass:list';
@use 'sass:map';
@use 'sass:meta';
@use './base';
@use '../common/scale';
@use '../common/util';
@use '../common/round';
@use '../common/config';

config.$scope: 'metrics';

//
// Base metrics configuration:
//

// The default width of a line of text, in characters.
$measure: config.get('measure', 60ch) !default;

//
// Fluid type scale configuration:
//

// minimum viewport width (in pixels)
$type-scale-min-width: config.get('type-scale-min-width', 318) !default;

// maximum viewport width (in pixels)
$type-scale-max-width: config.get('type-scale-max-width', 1110) !default;

// minimum font size (in pixels)
$type-scale-min-font-size: config.get('type-scale-min-font-size', base.$rem) !default;

// maximum font size (in pixels)
$type-scale-max-font-size: config.get('type-scale-max-font-size', 24) !default;

// line-height of minimum font size (as a ratio)
$type-scale-min-line-height: config.get('type-scale-min-line-height', 1.5) !default;

// line-height of maximum font size (as a ratio)
$type-scale-max-line-height: config.get('type-scale-max-line-height', 1.3) !default;

// minimum type scale
$type-scale-min-type-scale: config.get('type-scale-min-type-scale', minor-third) !default;

// maximum type scale
$type-scale-max-type-scale: config.get('type-scale-max-type-scale', major-third) !default;

// use viewport- or container-relative units
$type-scale-relative-to: config.get('type-scale-relative-to', viewport) !default;

// positive steps in the scale
$type-scale-positive-steps: config.get('type-scale-positive-steps', 5) !default;

// negative steps in the scale
$type-scale-negative-steps: config.get('type-scale-negative-steps', 2) !default;

// custom steps in the scale
$type-scale-custom-steps: config.get('type-scale-custom-steps', ()) !default;

// the name of the base step in the scale (only used with irregular scales)
$type-scale-base-step-name: config.get('type-scale-base-step-name', '0') !default;

// number of positive steps in the scale
$type-scale-total-positive-steps: if(
    meta.type-of($type-scale-positive-steps) == 'list',
    list.length($type-scale-positive-steps),
    $type-scale-positive-steps
);

// number of negative steps in the scale
$type-scale-total-negative-steps: if(
    meta.type-of($type-scale-negative-steps) == 'list',
    list.length($type-scale-negative-steps),
    $type-scale-negative-steps
);

//
// Fluid spacing scale configuration:
//

// minimum viewport width (in pixels)
$spacing-scale-min-width: config.get('spacing-scale-min-width', $type-scale-min-width) !default;

// maximum viewport width (in pixels)
$spacing-scale-max-width: config.get('spacing-scale-max-width', $type-scale-max-width) !default;

// minimum base (i.e. "S" t-shirt size) spacing (in pixels)
$spacing-scale-min-spacing: config.get('spacing-scale-min-spacing', 18) !default;

// maximum base (i.e. "S" t-shirt size) spacing (in pixels)
$spacing-scale-max-spacing: config.get('spacing-scale-max-spacing', 20) !default;

// use viewport- or container-relative units
$spacing-scale-relative-to: config.get(
    'spacing-scale-relative-to',
    $type-scale-relative-to
) !default;

// set of positive multipliers in the scale
$spacing-scale-positive-multipliers: config.get(
    'spacing-scale-positive-multipliers',
    (1.5, 2, 3, 4, 6)
) !default;

// set of negative multipliers in the scale
$spacing-scale-negative-multipliers: config.get(
    'spacing-scale-negative-multipliers',
    (0.75, 0.5, 0.25)
) !default;

// custom steps in the scale
$spacing-scale-custom-sizes: config.get('spacing-scale-custom-sizes', util.empty-map()) !default;

// custom spans in the scale
$spacing-scale-custom-spans: config.get('spacing-scale-custom-spans', ()) !default;

// rounds the px spacing scale steps to the nearest multiple of this value, if supported
$spacing-scale-round-to-px: config.get('spacing-scale-round-to-px', null) !default;

// rounds the rem spacing scale steps to the nearest multiple of this value, if supported
$spacing-scale-round-to-rem: config.get('spacing-scale-round-to-rem', null) !default;

//
// Fluid grid configuration:
//

// minimum viewport width (in pixels)
$grid-min-width: config.get('grid-min-width', $spacing-scale-min-width) !default;

// maximum viewport width (in pixels)
$grid-max-width: config.get('grid-max-width', $spacing-scale-max-width) !default;

// the name of a spacing scale span to use as the grid gutter
$grid-gutter-space: config.get('grid-gutter-space', s-m) !default;

// the name of a spacing scale step to use as the maximum column width
$grid-max-column-width-space: config.get('grid-max-column-width-space', xl) !default;

// number of columns in the grid
$grid-columns: config.get('grid-columns', 12) !default;

//
// -------------------------------------------------------------------------------------------------
//

$-space-scale: scale.calculate-space-scale(
    (
        'minWidth': $spacing-scale-min-width,
        'maxWidth': $spacing-scale-max-width,
        'minSize': $spacing-scale-min-spacing,
        'maxSize': $spacing-scale-max-spacing,
        'positiveSteps': $spacing-scale-positive-multipliers,
        'negativeSteps': $spacing-scale-negative-multipliers,
        'customSteps': $spacing-scale-custom-sizes,
        'customSpans': $spacing-scale-custom-spans,
        'relativeTo': $spacing-scale-relative-to,
        'gridColumns': $grid-columns,
        'gridMaxColumnWidth': $grid-max-column-width-space,
        'gridGutterSpan': $grid-gutter-space,
    )
);

$space-scale: (none); // the list off all available t-shirt sizes in the spacing scale

@each $size in map.get($-space-scale, 'sizes') {
    $space-scale: list.append($space-scale, map.get($size, 'label'));
}
@each $span in map.get($-space-scale, 'spans') {
    $space-scale: list.append($space-scale, map.get($span, 'label'));
}

$-grid: map.get($-space-scale, 'grid');

$-type-scale: scale.calculate-type-scale(
    (
        'minWidth': $type-scale-min-width,
        'maxWidth': $type-scale-max-width,
        'minFontSize': $type-scale-min-font-size,
        'maxFontSize': $type-scale-max-font-size,
        'minLineHeight': $type-scale-min-line-height,
        'maxLineHeight': $type-scale-max-line-height,
        'minTypeScale': $type-scale-min-type-scale,
        'maxTypeScale': $type-scale-max-type-scale,
        'positiveSteps': $type-scale-positive-steps,
        'negativeSteps': $type-scale-negative-steps,
        'customSteps': $type-scale-custom-steps,
        'baseStepName': $type-scale-base-step-name,
        'relativeTo': $type-scale-relative-to,
    )
);

// the list of all available fluid type scale steps (without custom steps)
$type-scale-steps: util.pluck(map.get($-type-scale, 'steps'), 'step');

// the list of all available custom type scale steps
$custom-type-scale-steps: util.pluck(map.get($-type-scale, 'custom'), 'step');

@mixin styles {
    :root {
        // The default measure (width of a line of text, in characters).
        --measure: #{$measure};

        @each $scale in map.get($-type-scale, 'steps') {
            // Fluid type scale steps (--step-0, --step-1, ...)
            --step-#{map.get($scale, 'step')}: #{map.get($scale, 'clamp')};
            --step-#{map.get($scale, 'step')}-height: #{map.get($scale, 'lineHeightClamp')};
        }

        @each $scale in map.get($-type-scale, 'custom') {
            // Custom type scale steps (--step-<name>)
            --step-#{map.get($scale, 'step')}: #{map.get($scale, 'clamp')};
            --step-#{map.get($scale, 'step')}-height: #{map.get($scale, 'lineHeightClamp')};
        }

        --space-none: 0;

        @each $size in map.get($-space-scale, 'sizes') {
            // Fluid pixel space scale (--space-s, --space-m, --space-l, ...)
            // --space-#{map.get($size, 'label')}: #{map.get($size, 'clampPx')};
            @include round.prop(
                '--space-#{map.get($size, 'label')}',
                map.get($size, 'clampPx'),
                $spacing-scale-round-to-px
            );

            // Fluid rem space scale (--space-s-rem, --space-m-rem, ---space-l-rem, ...)
            // --space-#{map.get($size, 'label')}-rem: #{map.get($size, 'clamp')};
            @include round.prop(
                '--space-#{map.get($size, 'label')}-rem',
                map.get($size, 'clamp'),
                $spacing-scale-round-to-rem
            );
        }
        @each $span in map.get($-space-scale, 'spans') {
            // Fluid pixel space scale spans (--space-s-m, --space-m-xl, ...)
            @include round.prop(
                '--space-#{map.get($span, 'label')}',
                map.get($span, 'clampPx'),
                $spacing-scale-round-to-px
            );

            // Fluid rem space scale spans (--space-s-m-rem, --space-m-xl-rem, ...)
            @include round.prop(
                '--space-#{map.get($span, 'label')}-rem',
                map.get($span, 'clamp'),
                $spacing-scale-round-to-rem
            );
        }
        @each $size in map.get($-space-scale, 'customSizes') {
            // Fluid pixel space scale (--space-foo, --space-bar, ...)
            @include round.prop(
                '--space-#{map.get($size, 'label')}',
                map.get($size, 'clampPx'),
                $spacing-scale-round-to-px
            );

            // Fluid rem space scale (--space-foo-rem, --space-bar-rem, ...)
            @include round.prop(
                '--space-#{map.get($size, 'label')}-rem',
                map.get($size, 'clamp'),
                $spacing-scale-round-to-rem
            );
        }

        // Fluid grid columns
        --grid-columns: #{$grid-columns};

        // Maximum width of the fluid grid
        --grid-max-inline-size: #{map.get($-grid, 'maxWidth')};

        // The size of the fluid grid gaps
        --grid-gutter: #{map.get($-grid, 'gutterClamp')};
    }
}
