@use 'common/respond';

@mixin styles {
    .u-hide {
        display: none !important;

        @each $breakpoint in respond.breakpoint-names() {
            @include respond.until($breakpoint) {
                &\@#{$breakpoint} {
                    display: none !important;
                }
            }
        }
    }

    .u-hide-visually {
        border: 0 !important;
        clip: rect(0 0 0 0) !important;
        clip-path: inset(50%) !important;
        margin: -1px !important;
        overflow: hidden !important;
        padding: 0 !important;
        position: absolute !important;
        white-space: nowrap !important;
        inline-size: 1px !important;
        block-size: 1px !important;
    }
}
