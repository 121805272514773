@use 'sass:math';
@use 'common/util';
@use 'common/respond';
@use 'config/modules/marquee' as config;

@function -width-to-duration($width) {
    @return math.div(util.strip-unit($width), config.$marquee-speed) * 1s;
}

@mixin styles {
    $-ns: m-marquee;

    .#{$-ns} {
        $-from: 0;
        $-to: calc(-100% - var(--marquee-gap, var(--gap)));

        --gap: var(--space-s);
        --marquee-speed: #{config.$marquee-speed}; // px/s
        --marquee-default-duration: #{-width-to-duration(respond.breakpoint-value('base'))};
        --marquee-from: #{$-from};
        --marquee-to: #{$-to};

        &--reverse {
            --marquee-from: #{$-to};
            --marquee-to: #{$-from};
        }

        @each $breakpoint in respond.breakpoint-names() {
            @include respond.from($breakpoint) {
                --marquee-default-duration: #{-width-to-duration(
                        respond.breakpoint-value($breakpoint)
                    )};
            }
        }

        display: flex;
        gap: var(--marquee-gap, var(--gap));
        overflow: hidden;
        user-select: none;

        &__content,
        > * {
            display: flex;
            gap: var(--marquee-gap, var(--gap));
            flex-shrink: 0;
            justify-content: space-around;
            white-space: nowrap;
            max-inline-size: none;
            will-change: transform;
            animation: marquee var(--marquee-duration, var(--marquee-default-duration)) linear
                infinite;
        }

        &:hover {
            .#{$-ns}__content,
            > * {
                animation-play-state: paused;
            }
        }
    }

    @keyframes marquee {
        0% {
            transform: translateX(var(--marquee-from));
        }

        100% {
            transform: translateX(var(--marquee-to));
        }
    }
}
