@use 'sass:meta';
@use 'sass:string';

$element-separator: '__';
$modifier-separator: '--';

///
/// Returns a BEM selector based on the given block, element, and modifier.
///
/// @param {String} $block    - The block name.
/// @param {String} $element  - The element name, defaults to null. If not set, or is set to
///                             an empty string, no element is added to the selector.
/// @param {String} $modifier - The modifier name, defaults to null. If not set, or is set to
///                             an empty string or 'default', no modifier is added to the selector.
///
@function selector($block, $element: null, $modifier: null) {
    $selector: $block;
    @if not string.index($selector, '.') and not string.index($selector, '&') {
        $selector: '.#{$selector}';
    }
    @if $element and $element != '' {
        $selector: '#{$selector}#{$element-separator}#{$element}';
    }
    @if $modifier and $modifier != 'default' and $modifier != '' {
        $selector: '#{$selector}#{$modifier-separator}#{$modifier}';
    }
    @return $selector;
}

///
/// Wraps the content in a BEM modifier of a current selector.
///
/// If the $modifier is 'default' or an empty string, the content is emitted
/// as is, without being wrapped in a nested class.
///
/// @param {String} $modifier - The modifier name to apply (without '--').
///
@mixin modifier($modifier, $block: '&') {
    @if not meta.content-exists() {
        @error 'The bem.modifier() mixin must be called with the content to wrap.';
    }
    $selector: selector($block, $modifier: $modifier);
    @if $selector != '&' {
        #{$selector} {
            @content;
        }
    } @else {
        @content;
    }
}

///
/// Wraps the content in a BEM element of a current selector.
///
/// If the $element is 'default' or an empty string, the content is emitted
/// as is, without being wrapped in a nested class.
///
/// @param {String} $element - The element name to apply (without '__').
///
@mixin element($element, $block: '&') {
    @if not meta.content-exists() {
        @error 'The bem.element() mixin must be called with the content to wrap.';
    }
    $selector: selector($block, $element: $element);
    @if $selector != '&' {
        #{$selector} {
            @content;
        }
    } @else {
        @content;
    }
}
