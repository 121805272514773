@use '../common/layer';
@use '../config/colors';

@mixin styles {
    body {
        @if not layer.is-excluded('config/colors') {
            --bg-color: var(--color-#{colors.$default-background-color});
            --text-color: var(--color-#{colors.$default-text-color});
        } @else {
            --bg-color: #fff;
            --text-color: #000;
        }

        background-color: var(--bg-color);
        color: var(--text-color);
    }
}
