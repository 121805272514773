@use 'sass:map';
@use 'sass:math';
@use 'sass:meta';
@use './util';
@use './font-weight';
@use './font-stack' as *;
@use '../config/typography';

///
/// "Normalizes" type style definition by recursively resolving type-style references
/// and handling other "shortcut" props and values.
///
@function -get-normalized-type-style($name) {
    $type-style: util.get-first(typography.$type-styles, $name);

    @if not $type-style {
        @error "Unknown type style '#{$name}'";
    }

    // nested type-style reference
    @if map.has-key($type-style, 'type-style') {
        $refs: util.ensure-list(map.get($type-style, 'type-style'));
        $type-style: map.remove($type-style, 'type-style');
        @each $ref in $refs {
            $type-style: map.merge(-get-normalized-type-style($ref), $type-style);
        }
    }

    // font-stack reference
    @if map.has-key($type-style, 'font-stack') {
        $ref: map.get($type-style, 'font-stack');
        $type-style: map.set(
            map.remove($type-style, 'font-stack'),
            'font-family',
            font-stack($ref)
        );
    }

    // font-size list support (sets both font-size and line-height)
    @if map.has-key($type-style, 'font-size') {
        $value: map.get($type-style, 'font-size');
        @if meta.type-of($value) == 'list' {
            $type-style: map.merge(
                $type-style,
                (
                    font-size: util.head($value),
                    line-height: util.tail($value),
                )
            );
        }
    }

    // font weight common name -> value
    @if map.has-key($type-style, 'font-weight') {
        $type-style: map.set(
            $type-style,
            'font-weight',
            font-weight.get(map.get($type-style, 'font-weight'))
        );
    }

    // percentage support for letter-spacing
    @if map.has-key($type-style, 'letter-spacing') {
        $value: map.get($type-style, 'letter-spacing');
        @if util.is-percentage($value) {
            $type-style: map.set($type-style, 'letter-spacing', util.to-factor($value) * 1ch);
        }
    }

    @return $type-style;
}

///
/// Applies the named type style.
///
/// The $name argument can be a single type style name or
/// a list of type style names to try in order. Example:
///
/// @include type-style(('h1', 'title'));  // resolves to 'h1' if it exists, otherwise to 'title'
///
@mixin type-style($name, $important: false, $with: null) {
    @each $prop, $value in map.merge(-get-normalized-type-style($name), util.ensure-map($with)) {
        @if $important {
            #{$prop}: $value !important;
        } @else {
            #{$prop}: $value;
        }
    }
}
