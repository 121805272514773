@use 'sass:map';
@use '../common/util';
@use '../common/transition' as *;
@use '../config/modules/toggle' as config;

$-variants: util.without(config.$toggle-variants, 'default');
$-config: util.transpose(config.$toggle-config);

@function -resolve($param, $variant: 'default', $default: null) {
    $size: map.get($-config, $variant, $param);
    @if not $size {
        @return $default;
    }
    @return if(
        util.is-percentage($size),
        calc(map.get($-config, $variant, 'size') * util.to-factor($size)),
        $size
    );
}

@function -default($param...) {
    @return map.get($-config, 'default', $param...);
}

@mixin -colors($variant, $state, $with-border: true) {
    --toggle-color: #{map.get($-config, $variant, 'colors', $state, 'background')};
    --toggle-thumb-color: #{map.get($-config, $variant, 'colors', $state, 'thumb')};
    @if $with-border {
        --toggle-border-color: #{map.get($-config, $variant, 'colors', $state, 'border')};
    }
}

@mixin styles {
    $-ns: 'm-toggle';

    .#{$-ns} {
        $-size: -resolve('size');
        $-inline-size: -resolve('inline-size', $default: calc(var(--toggle-size) * 2));
        $-border-width: -resolve('border-width', $default: 0px);
        $-with-border: $-border-width != 0px;
        $-radius: -resolve('radius');
        $-padding: -resolve('padding');

        --toggle-color: #{-default('colors', 'default', 'background')};
        --toggle-thumb-color: #{-default('colors', 'default', 'thumb')};
        --toggle-size: #{$-size};
        --toggle-thumb-size: #{calc(var(--toggle-size) - ($-padding + $-border-width) * 2)};
        --toggle-inline-size: #{$-inline-size};

        @if $-with-border {
            --toggle-border-color: #{-default('colors', 'default', 'border')};
        }

        display: inline-block;

        &__thumb {
            @if $-radius {
                border-radius: $-radius;
            }

            display: block;
            background: var(--toggle-thumb-color);
            block-size: var(--toggle-thumb-size);
            inline-size: var(--toggle-thumb-size);
            transform: translateX(var(--toggle-thumb-x, 0));

            @include transition(transform, $duration: 50%);
        }

        > input:focus-visible + label {
            @include -colors('default', 'focus', $-with-border);
        }

        > input:checked:focus-visible + label {
            @include -colors('default', 'selected-focus', $-with-border);
        }

        > input {
            &[disabled] + label {
                @include -colors('default', 'disabled', $-with-border);

                cursor: not-allowed;
            }

            &:checked + label {
                @include -colors('default', 'selected', $-with-border);

                .#{$-ns}__thumb {
                    --toggle-thumb-x: calc(var(--toggle-inline-size) - var(--toggle-size));
                }

                &:hover {
                    @include -colors('default', 'selected-hover', $-with-border);
                }

                &:active {
                    @include -colors('default', 'selected-active', $-with-border);
                }
            }

            &[disabled]:checked + label {
                @include -colors('default', 'selected-disabled', $-with-border);
            }
        }

        > label {
            display: block;
            padding: $-padding;
            background: var(--toggle-color);
            cursor: pointer;
            block-size: var(--toggle-size);
            inline-size: var(--toggle-inline-size);

            @if $-with-border {
                border: $-border-width solid var(--toggle-border-color, var(--toggle-thumb-color));
            }

            @if $-radius {
                border-radius: $-radius;
            }

            &:hover {
                @include -colors('default', 'hover', $-with-border);
            }

            &:active {
                @include -colors('default', 'active', $-with-border);
            }
        }

        @include util.apply-styles(-default('styles'), 'default');

        @each $variant in $-variants {
            &--#{$variant} {
                $-variant-size: -resolve('size', $variant);
                $-variant-inline-size: -resolve(
                    'inline-size',
                    $variant,
                    $default: calc(var(--toggle-size) * 2)
                );
                $-variant-border-width: -resolve('border-width', $variant, $default: 0px);
                $-variant-with-border: $-variant-border-width != 0px;
                $-variant-radius: -resolve('radius', $variant);
                $-variant-padding: -resolve('padding', $variant);

                @include -colors($variant, 'default', $-variant-with-border);

                @if $-variant-size != $-size {
                    --toggle-size: #{$-variant-size};
                }
                @if ($-variant-size != $-size) or
                    ($-variant-padding != $-padding) or
                    ($-variant-border-width != $-border-width)
                {
                    --toggle-thumb-size: #{calc(
                            var(--toggle-size) - ($-variant-padding + $-variant-border-width) * 2
                        )};
                }
                @if $-variant-inline-size != $-inline-size or $-variant-size != $-size {
                    --toggle-inline-size: #{$-variant-inline-size};
                }

                &__thumb {
                    @if $-variant-radius != $-radius {
                        border-radius: if($-variant-radius, $-variant-radius, 0px);
                    }
                }

                > input:focus-visible + label {
                    @include -colors($variant, 'focus', $-variant-with-border);
                }

                > input:checked:focus-visible + label {
                    @include -colors($variant, 'selected-focus', $-variant-with-border);
                }

                > input {
                    &[disabled] + label {
                        @include -colors($variant, 'disabled', $-variant-with-border);
                    }

                    &:checked + label {
                        @include -colors($variant, 'selected', $-variant-with-border);

                        &:hover {
                            @include -colors($variant, 'selected-hover', $-variant-with-border);
                        }

                        &:active {
                            @include -colors($variant, 'selected-active', $-variant-with-border);
                        }
                    }

                    &[disabled]:checked + label {
                        @include -colors($variant, 'selected-disabled', $-variant-with-border);
                    }
                }

                > label {
                    @if $-variant-with-border {
                        @if not $-with-border {
                            border: $-variant-border-width
                                solid
                                var(--toggle-border-color, var(--toggle-thumb-color));
                        } @else if $-variant-border-width != $-border-width {
                            border-width: $-variant-border-width;
                        }
                    } @else if $-with-border {
                        border: none;
                    }

                    @if $-variant-radius != $-radius {
                        border-radius: $-variant-radius;
                    }

                    &:hover {
                        @include -colors($variant, 'hover', $-variant-with-border);
                    }

                    &:active {
                        @include -colors($variant, 'active', $-variant-with-border);
                    }
                }

                @include util.apply-styles(map.get($-config, $variant, 'styles'), $variant);
            }
        }
    }
}
