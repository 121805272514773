@mixin styles {
    html {
        box-sizing: border-box;
    }

    * {
        &,
        &::before,
        &::after {
            box-sizing: inherit;
            border-style: solid;
            border-width: 0;
        }
    }
}
