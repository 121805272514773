@use '../config/metrics';
@use '../common/respond';

///
/// The Fluid Grid Layout primitive creates a fluid grid with the configurable number of columns.
///
/// @see https://utopia.fyi/grid/calculator/
///

@mixin styles {
    $-ns: l-fluid-grid;

    .#{$-ns} {
        display: grid;
        grid-template-columns: repeat(var(--grid-columns), 1fr);
        gap: var(--grid-gutter);
        max-inline-size: var(--grid-max-inline-size);

        @for $column from 1 through metrics.$grid-columns {
            &__cell-#{$column} {
                grid-column: span $column;
            }
        }

        @each $breakpoint in respond.breakpoint-names() {
            @for $column from 1 through metrics.$grid-columns {
                @include respond.until($breakpoint) {
                    &__cell-#{$column}\@#{$breakpoint} {
                        grid-column: span $column;
                    }
                }
            }
        }
    }
}
