@use 'sass:list';
@use 'sass:map';
@use './util';
@use '../config/responsiveness' as config;

///
/// Returns the value of a named breakpoint.
///
@function breakpoint-value($key) {
    @return map.get(config.$breakpoints, if($key == 'base', config.$base-breakpoint, $key));
}

///
/// Returns the list of names of all breakpoints.
///
@function breakpoint-names() {
    @return map.keys(config.$breakpoints);
}

// If possible, converts $value to a rem unit value
@function -try-to-rem($value) {
    @return util.to-unit($value, rem, $incompatible: abort);
}

@mixin -query($args...) {
    $params: util.keywords($args);

    $until: util.get-first($params, (until, 1));
    $from: util.get-first($params, from);

    @if not $from and not $until {
        @error 'No $from or $until value provided.';
    }

    $media: util.get($params, media, config.$media);
    $container: util.get($params, container);

    $from: util.get(config.$breakpoints, $from, $from);
    $until: util.get(config.$breakpoints, $until, $until);

    $query: ();

    @if $from {
        $query: list.append($query, '(min-width: #{-try-to-rem($from)})');
    }

    @if $until {
        $query: list.append($query, '(max-width: #{-try-to-rem($until)})');
    }

    @each $key, $value in map.remove($params, until, from, media, container) {
        @if not util.to-number($key) {
            $query: list.append(
                $query,
                '(#{$key}: #{-try-to-rem(util.get(config.$breakpoints, $value, $value))})'
            );
        }
    }

    $query: util.concat($query, ' and ');

    @if $media != 'all' {
        @if $container {
            @error 'Cannot mix $container with $media.';
        }

        $query: 'only #{$media} #{$query}';
    }

    @if $container {
        @container #{if($container == true, '', $container)} #{$query} {
            @content;
        }
    } @else {
        @media #{$query} {
            @content;
        }
    }
}

@mixin until($until, $args...) {
    $params: util.keywords($args);
    @include -query(
        map.merge(
            $params,
            (
                until: $until,
            )
        )
    ) {
        @content;
    }
}

@mixin from($from, $args...) {
    $params: util.keywords($args);
    @include -query(
        map.merge(
            $params,
            (
                from: $from,
            )
        )
    ) {
        @content;
    }
}

@mixin container($args...) {
    $params: util.keywords($args);
    @include -query(map.set($params, 'container', util.get-first($params, (container, 1), true))) {
        @content;
    }
}
