///
/// The Box Layout primitive is used to define a "box", i.e. a container for something.
/// The only thing a Box Layout does is defining a padding and setting display to block, nothing more, nothing less.
///
/// You can control the size of the padding via the `--gap` variable.
///
///
///             Box Layout
///   /---------------------------\
///   | ######################### | <-- Padding
///   | # +-------------------+ # |
///   | # |                   | # |
///   | # |                   | # | <== Content
///   | # |                   | # |
///   | # +-------------------+ # |
///   | ######################### | <-- Padding
///   \---------------------------/
///      ↑                      ↑
///   Padding                Padding
///
/// Configuration:
///    <none>
///
/// Variables:
///     --gap                       The size of padding. Defaults to var(--space-s).
///     --box-gap                   The box-specific gap that overrides the --gap. Unset by default.
///
/// Usage:
///
///     <p class="l-box u-gap--xl">
///         Lorem ipsum dolor sit amet, consectetur adipiscing elit.
///     </p>
///
/// Block:
///    .l-box
///
/// Elements:
///    <none>
///
/// Modifiers:
///    <none>
///

@mixin styles {
    $-ns: l-box;

    .#{$-ns} {
        --gap: var(--space-s);

        display: block;
        padding: var(--box-gap, var(--gap));
    }
}
